import React, { useEffect, useRef, useState } from "react";
import styles from "./App.module.css";
import { Hero } from "./components/Hero";
import { Buttons } from "./components/Buttons";
import { Welcome } from "./components/Welcome";
import { Features } from "./components/Features/Features";
//import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { useClickAway } from "react-use";
import classNames from "classnames";

function App() {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const [fixed, setFixed] = useState(true);
  const [fElement, setFElement] = useState(null);
  const chatStyle = classNames(styles.chatBlock, {
    [styles.active]: active,
  });

  const chatContainer = classNames(styles.chatContainer, {
    [styles.fixed]: fixed,
    [styles.absolute]: !fixed,
  });

  useClickAway(ref, () => {
    setActive(false);
  });
  let Chatra = require("@chatra/chatra");
  let config = {
    setup: {
      mode: "frame",
      injectTo: "footer",
      //startHidden: true,
      customWidgetButton: "styles.chatBtn",
      disableChatOpenHash: true,
      colors: {
        buttonText: "#fff",
        buttonBg: "#ef6324",
        clientBubbleBg: "#58b2ce",
        agentBubbleBg: "#5d6db1",
      },
    },
    ID: `${process.env.REACT_APP_CHAT_PUBLIC_KEY}`,
  };
  useEffect(() => {
    Chatra("init", config);
  }, []);

  useEffect(() => {
    setFElement(document.getElementById("footer_anchor"));
  }, []);

  useEffect(() => {
    if (fElement) {
    }
  }, [fElement]);

  document.addEventListener("scroll", () => {
    if (
      document.documentElement.scrollHeight ===
      window.pageYOffset + window.innerHeight
    ) {
      setFixed(false);
    }
  });

  const visibilityCheck = () => {
    if (fElement) {
      if (fElement.getClientRects()[0].top < window.innerHeight) {
        setFixed(false);
      } else setFixed(true);
    }
  };

  let observer = new IntersectionObserver(visibilityCheck, {
    threshold: 0,
  });

  useEffect(() => {
    if (fElement) {
      observer.observe(fElement);
    }
  }, [fElement]);

  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <Hero />
      </header>
      <main className={styles.mainSection}>
        <Buttons/>
        <Welcome />
        <Features />
        {/*<Contact />*/}
      </main>

      <footer id={"footer_anchor"}>
        <div id={"chat"} className={chatContainer}>
          <div className={styles.chatWrap} ref={ref}>
            <button
              className={styles.chatBtn}
              onClick={() => setActive(!active)}
            >
              LIVECHAT
            </button>
            <div className={chatStyle} id={"footer"}></div>
          </div>
        </div>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
