import React from "react";
import styles from "./converse.module.css";

export function Converse() {
  return (
    <span className={styles.converseContainer}>
      <span className={styles.converseText}> CON</span>
      <span style={{color: "#6c6e70"}}>VERSE-</span>
      <span className={styles.converseText}>X</span>
    </span>
  );
}
