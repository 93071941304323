import React, { useState } from "react";
import styles from "./buttons.module.css";
import { Form, Input, Modal, notification, Space } from "antd";
import classNames from "classnames";
import mail from "../../assets/icons/mail.webp";
import whatsapp from "../../assets/icons/whatsapp.webp";
import calendar from "../../assets/icons/calendar_icon.webp";
import { PopupButton } from "react-calendly";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";

const ContactForm = ({ open, onCreate, onCancel, confirmLoading }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      confirmLoading={confirmLoading}
      open={open}
      title="Kontakt Formular"
      okText="Nachricht senden"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} size={"large"} layout="vertical" name="form_in_modal">
        <Form.Item
          name="from_name"
          label="Dein Name"
          rules={[
            {
              required: true,
              message: "Das Namensfeld ist erforderlich",
            },
            {
              min: 2,
              message: "Name ist zu kurz",
            },
            {
              max: 50,
              message: "Name ist zu lang",
            },
          ]}
        >
          <Input placeholder="Dein Name" />
        </Form.Item>
        <Form.Item
          name="mail"
          label="Email"
          rules={[
            {
              required: true,
              message: "Das Email ist erforderlich",
            },
            {
              type: "email",
              message: "Ungültige E-Mail-Adresse",
            },
          ]}
        >
          <Input type="email" placeholder={"Email"} />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              type: "tel",
            },
          ]}
        >
          <PhoneInput
            style={{
            //  padding: "1px 0 0 0",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "6px",
              //height: "32px",
            }}
            defaultMask="... ... ..."
            inputProps={{
              name: "phone",
              padding: 0,
            }}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else return !value.match(/1234/);
            }}
            disableSearchIcon={true}
            copyNumbersOnly={false}
            disableDropdown={true}
            inputStyle={{
              background: "transparent",
              //border: "1px solid grey",
              border: "none",
              width: "100%",
              outline: "none",
            }}
            buttonStyle={{
              height: "0",
              background: "transparent",
              outline: "none",
              border: "none",
              margin: "auto",
            }}
          />
        </Form.Item>
        <Form.Item
          name="emailBody"
          label="Fragen & Anregungen?"
          rules={[
            {
              required: true,
              message: "Dieses Feld ist erforderlich",
            },
          ]}
        >
          <TextArea
            type="textfield"
            placeholder={"Fragen & Anregungen?"}
            showCount
            maxLength={200}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export function Buttons() {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const mailIcon = classNames(styles.iconContainer, styles.mailIcon);
  const whatsappIcon = classNames(styles.iconContainer, styles.whatsappIcon);
  const calendarIcon = classNames(styles.iconContainer, styles.timeIcon);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, description) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const onCreate = async (values) => {
    const params = { ...values };
    params.phone =
      params.phone && params.phone.length > 0
        ? params.phone.padStart(params.phone.length + 1, "+")
        : "";
    params.emailFromName = "Converse-X für Agenturen";
    params.emalFrom = "info@zvwhome.com";
    params.emailSubject = "Message from agencies.converse-x-chat Web Site";
    params.emailBody = params.emailBody
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    params.from_name = params.from_name
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");

    params.phone = params.phone
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");
    params.mail = params.mail
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot");

    const phone =
      params.phone.length > 0 ? `Phone / WhatsApp: ${params.phone}` : "";
    const phoneShow = params.phone.length > 0 ? "table-cell" : "none";
    let colSpan = 2;
    if (params.phone.length > 0) {
      colSpan = 1;
    }

    if (params.phone.length === 0) {
      colSpan = 0;
    }

    const body = `<table style="width: 100%";>
<tr style = "background-color: #9f9f9f;">
<td style="padding: 10px; border: #e9e9e9 1px solid;"><b>Sender Name: ${params.from_name}</b></td>
<td style="padding: 10px; border: #e9e9e9 1px solid;"><b>Sender Email: ${params.mail}</b></td>
</tr>
<tr style = "background-color: #cdcccc;">
 
 <td colspan="2" style="padding: 10px; border: #e9e9e9 1px solid;"><b>Sender Message: </b> ${params.emailBody}</td>
</tr>

<tr  style = "background-color: #9f9f9f;">
<td colspan = ${colSpan} style="padding: 10px; border: #e9e9e9 1px solid; display: ${phoneShow}"><b>${phone}</b></td>
</tr> 
</table>`;

    params.emailRecipient = "sales@converse-x-chat.com";
    //params.emailRecipient = "is@zvwhome.com";
    const request = new FormData();
    request.append("emailFromName", params.emailFromName);
    request.append("emailRecipient", params.emailRecipient);
    request.append("emailFrom", params.emalFrom);
    request.append("emailSubject", params.emailSubject);
    request.append("emailBody", body);
    //request.append("g-recaptcha-response", params["g-recaptcha-response"]);
    setConfirmLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROUTE}`, {
        method: "POST",
        body: request,
      });

      const data = await response.json();
      if (data) {
        if (data.STATUS === 1) {
          setOpen(false);
          setConfirmLoading(false);
          openNotificationWithIcon(
            "success",
            "Erfolgreich",
            "Nachricht erfolgreich gesendet"
          );
        } else {
          setOpen(false);
          setConfirmLoading(false);
          openNotificationWithIcon(
            "error",
            "Fehler",
            `Fehler beim Senden der Nachricht - ${data.message}`
          );
        }
      }
    } catch (err) {
      setOpen(false);
      setConfirmLoading(false);
      openNotificationWithIcon(
        "error",
        "Fehler",
        `Fehler beim Senden der Nachricht - ${err.message}`
      );
    }
  };

  const Mail = () => {
    return (
      <div className={styles.buttonWrap}>
        <div className={mailIcon}>
          <img src={mail} className={styles.iconImg} alt={"Mail"} />
        </div>
        <p className={styles.iconText}>
          Jetzt Informationen <br /> per Email anfordern
        </p>
      </div>
    );
  };

  const Whatsapp = () => {
    return (
      <div className={styles.buttonWrap}>
        <div className={whatsappIcon}>
          <img src={whatsapp} className={styles.iconImg} alt={"Whatsapp"} />
        </div>
        <p className={styles.iconText}>
          Jetzt direkt <br /> WhatsApp-Kontakt
        </p>
      </div>
    );
  };
  const Calendar = () => {
    return (
      <div className={styles.buttonWrap}>
        <div className={calendarIcon}>
          <img src={calendar} className={styles.iconImg} alt={"Calendar"} />
        </div>
        <p className={styles.iconText}>
          Termin für eine <br /> LIVE-Präsentation
        </p>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <button className={styles.button} onClick={showModal}>
        <Mail />
      </button>
      <a
        href={
          "https://wa.me/4915219523355?text=Hallo%2C%20ich%20bin%20eine%20Agentur%20und%20interessiere%20mich%20f%C3%BCr%20Ihr%20Produkt"
        }
        className={styles.button}
      >
        <Whatsapp />
      </a>
      <PopupButton
        className={styles.button}
        url="https://calendly.com/converse-x-chat/30min"
        rootElement={document.getElementById("root")}
        text={<Calendar />}
      />
      <ContactForm
        open={open}
        onCreate={onCreate}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}
