import React from "react";
import styles from "./welcome.module.css";
import { Converse } from "../Converse";

export function Welcome() {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>

        <h4 className={styles.mainHeader}>
             Revolutionieren Sie Ihr Dating-Chat-Platform-Geschäft mit {<Converse />}!
        </h4>
      </div>

      <p className={styles.description}>
          Bieten Sie Dating-Services an und stehen vor Herausforderungen? {<Converse />} ist
          die
          innovative Lösung, auf die Sie gewartet haben! Unser fortschrittliches Chat-
          Business-Toolset
          wurde speziell entwickelt, um Ihre Dating-Chat Plattform auf die nächste Stufe zu
          heben und
          gleichzeitig Ihre Arbeitsabläufe zu optimieren.

      </p>
    </div>
  );
}
