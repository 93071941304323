import React from "react";
import styles from "./features.module.css";
import features from "../../assets/icons/features.webp";
import apps from "../../assets/icons/apps.webp";
import frontend from "../../assets/icons/frontend.webp";
import cloud from "../../assets/icons/cloud.webp";
import chat from "../../assets/icons/chat.webp";
import admin from "../../assets/icons/admin.webp";
export function Features() {
  return (
    <div className={styles.container}>
      <h2 className={styles.mainHeader}>
        FEATURES, DIE IHRE AGENTURENHERZEN HÖHER
        SCHLAGEN LASSEN WERDEN!
      </h2>
      <div className={styles.mainSection}>
        <div className={styles.imageSection}>
          <img className={styles.imgFeatures} src={features} alt={"features"} />
        </div>
        <div className={styles.descriptionSection}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={apps}
                  alt={"apps icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Speziell entwickelte Apps, die Ihren Benutzern ein
                unvergleichliches Erlebnis bietet. Verbinden Sie sich
                über Push-Benachrichtigungen direkt mit Ihren
                Kunden – jederzeit und überall.
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={frontend}
                  alt={"frontend icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Egal ob am Schreibtisch oder unterwegs, unser Web-
                Frontend passt sich an Ihre Bedürfnisse an und
                bietet eine nahtlose Nutzererfahrung.
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={cloud}
                  alt={"cloud icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Ihre "Agentenzentrale" mit E-Mail, Dateiverwaltung,
                Videoanrufen, Handbüchern und mehr. Organisation
                war noch nie so einfach und sicher!
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={chat}
                  alt={"chat icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Gestalten Sie Ihren Chat, wie es Ihnen gefällt!
                Effizienz und Kontrolle sind in Ihrer Hand.
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={admin}
                  alt={"admin icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Behalten Sie den Überblick über Live-Umsätze, Traffic,
                Kunden und Mitarbeiter. Ihre Agentur wird floriert,
                und Sie haben die Zügel in der Hand.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
